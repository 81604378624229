import Vue from 'vue'
import App from './App.vue'
import router from "./route/index.js";

Vue.config.productionTip = false;

Vue.prototype.global = {};

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')
