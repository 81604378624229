import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//解决vue-router3.0以上版本，避免对当前位置冗余导航的警告信息：NavigationDuplicated: Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    routes: [
        {
            path: '/agent',
            component: () => import('../agent/view/Home'),
            meta: {title: '客服', keepAlive: false},
            children: [
                {
                    path: 'conversations',
                    component: () => import('../agent/view/Conversations'),
                    meta: {title: '会话', keepAlive: false},
                    children: [
                        {
                            path: 'chat',
                            component: () => import('../agent/view/Chat'),
                            meta: {title: '聊天', keepAlive: false},
                        }
                    ]
                },
                {
                    path: 'customers',
                    component: () => import('../agent/view/Customers'),
                    meta: {title: '联系人', keepAlive: false},
                }
            ]
        },
        {
            path: '/customer',
            component: () => import('../customer/index')
        }
    ]
});

export default router;
